@tailwind base;
@tailwind components;
@tailwind utilities;

/*
html,
body {
    font-family: 'Lucida Grande', sans-serif;
    !* You can also add other font styles like font-size, font-weight, etc. here if needed *!
}*/
